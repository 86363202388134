import { useMemo, useState } from "react";
import { useQuery } from "react-query";
import * as MainDbReturnTypes from "@sprycore/main-db-types/ReturnTypes";
import { PageLoader, Table } from "../../Components";

import { TableColumns } from "./ParticipantCol";
import { campaignClient } from "../../api";
import { CSVLink } from "react-csv";
import "./index.css";
import CustomModal from "../../Components/CustomModal";
import { convertCaps, getImage } from "../../helpers/utils";
import { showToast } from "../../Components/Toast/ToastManager";

declare const window: Window &
  typeof globalThis & {
    vex: any;
  };

function Participants() {
  const approveImage = "/assets/images/check.png";
  const declineImage = "/assets/images/decline.png";
  const {
    isLoading: isLoadingParticipants,
    data: participants,
    refetch,
  } = useQuery("getParticipants", async () => {
    const res: MainDbReturnTypes.Participants = await campaignClient.call("getParticipants", {});
    return res.participants;
  });

  const [filterTable, setFilterTable] = useState("");
  const [photoModal, setPhotoModal] = useState(false);
  const [approvalModal, setApprovalModal] = useState(false);
  const [declineModal, setDeclineModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({
    email: "",
    fullName: "",
    description: "",
    img: "",
    sk: "",
  });

  const data = useMemo(() => {
    if (participants && participants.length > 0) {
      return participants.map((participant: MainDbReturnTypes.Participant) => {
        const isValidLink = !!participant.metadata?.imageKey;
        const codeStatus = participant.metadata?.status;
        const img = isValidLink && codeStatus ? getImage(participant.metadata.imageKey) : "";
        const status =
          codeStatus === 1
            ? "Pending"
            : codeStatus === 2
            ? "Approved"
            : codeStatus === 3
            ? "Declined"
            : "NA";
            
        return {
          firstName: participant.firstName,
          lastName: participant.lastName,
          email: participant.email,
          creationTime: new Date(participant.creationTime),
          updateTime: new Date(participant.updateTime),
          sessionKey: participant.sessionKey,
          postal: participant?.postal ? participant?.postal : "",
          phone: participant?.phone ? participant?.phone : "",
          rules: participant.metadata?.rules ? participant.metadata?.rules.toLocaleString() : "NA",
          optin1: participant.metadata?.optin1
            ? participant.metadata?.optin1.toLocaleString()
            : "NA",
          description: participant.metadata?.userText ? participant.metadata?.userText : "NA",
          status,
          img,
          approve: (
            <button
              className="btn-approve"
              disabled={(codeStatus !== 1 && codeStatus !== 3) || !isValidLink}
              onClick={() => {
                setUserData({
                  email: "",
                  fullName: "",
                  description: "",
                  img: "",
                  sk: participant.sessionKey,
                });
                setApprovalModal(true);
              }}>
              <img src={approveImage} alt="approve" />
            </button>
          ),

          decline: (
            <button
              className="btn-decline"
              disabled={codeStatus !== 1 && codeStatus !== 2}
              onClick={() => {
                setUserData({
                  email: "",
                  fullName: "",
                  description: "",
                  img: "",
                  sk: participant.sessionKey,
                });
                setDeclineModal(true);
              }}>
              <img src={declineImage} alt="decline" />
            </button>
          ),
          photo: (
            <>
              {!!codeStatus ? (
                <span
                  className="pointer"
                  onClick={() => {
                    setPhotoModal(true);
                    setUserData({
                      img,
                      email: participant.email!,
                      fullName:
                        convertCaps(participant.firstName!) +
                        " " +
                        convertCaps(participant.lastName!),
                      description: participant.metadata?.userText!,
                      sk: "",
                    });
                  }}>
                  <img src={img ? img : "/assets/images/empty.png"} width="50" alt="photo" />
                </span>
              ) : (
                <></>
              )}
            </>
          ),
        };
      });
    }
  }, [participants]);

  const reportData = useMemo(() => {
    if (data) {
      return data.map((rp) => {
        return {
          "Creation Time": rp.creationTime,
          "Last Action": rp.updateTime,
          "First Name": rp.firstName,
          "Last Name": rp.lastName,
          Email: rp.email,
          Phone: "'" + rp.phone + "'",
          Postal: rp.postal,
          "Status Image": rp.status,
          "Image Link": rp.img,
          "Rules/Terms": rp.rules,
          "Marketing Opt-In": rp.optin1,
        };
      });
    }
  }, [data]);

  const updateStatus = async (decline?: boolean) => {
    // status 0-N/A(no image submit), 1-submitted/pending, 2-approved , 3-declined
    setLoading(true);
    try {
      const res: any = await campaignClient.call("updateStatus", {
        newStatus: decline ? 3 : 2,
        sessionKey: userData.sk,
      });
      setDeclineModal(false);
      if (res.result) {
        refetch();
        setLoading(false);
        showToast({
          content: decline ? "Photo declined" : "Photo approved",
          duration: 3000,
          error: false,
        });
      } else {
        setLoading(false);
        showToast({
          content: "Something went wrong, please try again.",
          duration: 3000,
          error: true,
        });
      }
    } catch (e) {
      console.log(e);
      window.vex.dialog.alert({
        unsafeMessage: `Something went wrong, please try again.`,
        callback: () => {
          return;
        },
      });
    }
  };

  const columns = TableColumns();

  if (isLoadingParticipants) {
    return <PageLoader />;
  }

  return !isLoadingParticipants && participants && participants.length > 0 ? (
    <>
      {photoModal && (
        <CustomModal>
          <div
            className="popup popup--alt"
            style={{
              position: "relative",
              backgroundColor: "#fff",
              paddingTop: "30px",
              paddingBottom: "30px",
            }}>
            {data && (
              <>
                <div className="modal-close">
                  {" "}
                  <span onClick={() => setPhotoModal(false)} className="modal-close-btn">
                    <img src="/assets/images/close.png" width="20" alt="close" />
                  </span>
                </div>
                <div className="popup__content">
                  <h3>{userData.fullName}</h3>
                  <p>({userData.email})</p>
                </div>
                <div className="popup__content center">
                  {/* <VideoPlayer url={data.player_embed_url} headtext={""} /> */}
                  <span className="">
                    <img src={userData.img} width="350" alt="photo" />
                  </span>
                </div>
                <p>
                  <b>Description: </b> {userData.description}
                </p>
                <div className="alignRight">
                  <button type="button" className="btn" onClick={() => setPhotoModal(false)}>
                    Close
                  </button>
                </div>
              </>
            )}
          </div>
        </CustomModal>
      )}
      {approvalModal && (
        <CustomModal>
          <div
            className="popup popup--alt"
            style={{
              position: "relative",
              backgroundColor: "#fff",
              paddingTop: "30px",
              paddingBottom: "30px",
            }}>
            <div className="modal-close">
              {" "}
              <span onClick={() => setApprovalModal(false)} className="modal-close-btn">
                <img src="/assets/images/close.png" width="20" alt="close" />
              </span>
            </div>
            <div className="popup__content">
              <h3>Approve photo?</h3>
              <p>Are you sure you want to approve this photo?</p>
            </div>
            <div className="popup__actions">
              <button
                type="button"
                className="popup__btn popup__btn--alt btn--outlined"
                onClick={() => {
                  setApprovalModal(false);
                }}>
                Cancel
              </button>
              {loading ? (
                <button type="button" className="btn popup__btn" disabled={loading}>
                  <i className="fas fa-circle-notch fa-spin"></i>
                </button>
              ) : (
                <button
                  type="button"
                  className="btn popup__btn"
                  onClick={() => {
                    updateStatus();
                    setApprovalModal(false);
                  }}>
                  Approve photo
                </button>
              )}
            </div>
          </div>
        </CustomModal>
      )}

      {declineModal && (
        <CustomModal>
          <div
            className="popup popup--alt"
            style={{
              position: "relative",
              backgroundColor: "#fff",
              paddingTop: "30px",
              paddingBottom: "30px",
            }}>
            <div className="modal-close">
              {" "}
              <span onClick={() => setDeclineModal(false)} className="modal-close-btn">
                <img src="/assets/images/close.png" width="20" alt="close" />
              </span>
            </div>
            <div className="popup__content">
              <h3>Decline photo?</h3>
              <p>Are you sure you want to decline this photo?</p>
            </div>
            <div className="popup__actions">
              <button
                className="popup__btn popup__btn--alt btn--outlined"
                onClick={() => {
                  setDeclineModal(false);
                }}>
                Cancel
              </button>
              {loading ? (
                <button type="button" className="btn popup__btn" disabled={loading}>
                  <i className="fas fa-circle-notch fa-spin"></i>
                </button>
              ) : (
                <button
                  type="button"
                  className="btn popup__btn"
                  onClick={() => {
                    updateStatus(true);
                    setDeclineModal(false);
                  }}>
                  Decline photo
                </button>
              )}
            </div>
          </div>
        </CustomModal>
      )}
      <div className="main__head">
        <h2 className="main__title">Participants</h2>

        <div className="main__actions">
          <div className="tabs js-tabs">
            <div className="search search--medium flexContainer">
              <div className="search__row">
                <label htmlFor="q" className="hidden">
                  Search
                </label>
                <input
                  type="search"
                  name="q"
                  id="q"
                  value={filterTable}
                  placeholder="Search"
                  className="search__field"
                  onChange={(e) => setFilterTable(e.target.value)}></input>
                <button type="submit" className="search__btn">
                  <svg className="ico-search">
                    <image xlinkHref="/assets/images/svg/ico-search.svg"></image>
                  </svg>
                </button>
              </div>

              <div className="search search--alt marginLeft">
                <div className="search__row search__row--input">
                  <CSVLink
                    filename={`Campaign-Participants-Report-${new Date().toLocaleDateString()}`}
                    className="btn btn--medium btn--mobile-small"
                    data={reportData ? reportData : ""}
                    asyncOnClick={true}
                    target="_blank">
                    Download Report
                  </CSVLink>
                </div>
              </div>
            </div>{" "}
          </div>
        </div>
      </div>

      <div className="tabs__body mt-5">
        <div className="table table--alt table--tabs table--big">
          <Table
            columns={columns}
            data={data ? data : []}
            tablePageSize={15}
            filterValue={filterTable}
            filterId="updateTime"
            desc={true}
          />
        </div>
      </div>
    </>
  ) : (
    <p>Currently there are no any participants to display.</p>
  );
}

export default Participants;
