import { Row, TableProps } from "react-table";

export const TableColumns = () => {
  const COLUMNS = [
    {
      Header: "No.",
      accessor: "colNumber",
      width: "1%",
      Cell: (TableInfo: TableProps) => {
        const nmb = TableInfo.row.index + 1;
        return nmb;
      },
    },
    {
      Header: "Create Time",
      sortType: (rowA: Row, rowB: Row, columnId: number) => {
        if (rowA.values[columnId] > rowB.values[columnId]) return 1;
        if (rowB.values[columnId] > rowA.values[columnId]) return -1;
        return 0;
      },
      accessor: "creationTime",
      Cell: (TableInfo: TableProps) => {
        const dateString = TableInfo.data[TableInfo.row.index].creationTime.toLocaleString();
        return dateString;
      },
    },
    {
      Header: "Updated Time",
      sortType: (rowA: Row, rowB: Row, columnId: number) => {
        if (rowA.values[columnId] > rowB.values[columnId]) return 1;
        if (rowB.values[columnId] > rowA.values[columnId]) return -1;
        return 0;
      },
      accessor: "updateTime",
      Cell: (TableInfo: TableProps) => {
        const dateString = TableInfo.data[TableInfo.row.index].updateTime.toLocaleString();
        return dateString;
      },
    },

    {
      Header: "First Name",
      accessor: "firstName",
      Cell: (TableInfo: TableProps) => {
        return TableInfo.data[TableInfo.row.index].firstName ? (
          <a
            className="link-more link-more--alt"
            href={`/participant/${TableInfo.data[TableInfo.row.index].sessionKey}`}>
            {TableInfo.data[TableInfo.row.index].firstName}
          </a>
        ) : (
          "N/A"
        );
      },
    },
    {
      Header: "Last Name",
      accessor: "lastName",
      Cell: (TableInfo: TableProps) => {
        return TableInfo.data[TableInfo.row.index].lastName ? (
          <a
            className="link-more link-more--alt"
            href={`/participant/${TableInfo.data[TableInfo.row.index].sessionKey}`}>
            {TableInfo.data[TableInfo.row.index].lastName}
          </a>
        ) : (
          "N/A"
        );
      },
    },
    {
      Header: "Email Address",
      accessor: "email",
      Cell: (TableInfo: TableProps) => {
        return TableInfo.data[TableInfo.row.index].email ? (
          <a
            className="link-more link-more--alt"
            href={`/participant/${TableInfo.data[TableInfo.row.index].sessionKey}`}>
            {TableInfo.data[TableInfo.row.index].email}
          </a>
        ) : (
          "N/A"
        );
      },
    },

    {
      Header: "Postal",
      accessor: "postal",
      Cell: (TableInfo: TableProps) => {
        return TableInfo.data[TableInfo.row.index].postal ? (
          <a
            className="link-more link-more--alt"
            href={`/participant/${TableInfo.data[TableInfo.row.index].sessionKey}`}>
            {TableInfo.data[TableInfo.row.index].postal}
          </a>
        ) : (
          "N/A"
        );
      },
    },

    {
      Header: "Phone",
      accessor: "phone",
      Cell: (TableInfo: TableProps) => {
        return TableInfo.data[TableInfo.row.index].phone ? (
          <a
            className="link-more link-more--alt"
            href={`/participant/${TableInfo.data[TableInfo.row.index].sessionKey}`}>
            {TableInfo.data[TableInfo.row.index].phone}
          </a>
        ) : (
          "N/A"
        );
      },
    },
    {
      Header: "Photo",
      accessor: "photo",
    },
    {
      Header: "Description",
      accessor: "description",
      Cell: (TableInfo: TableProps) => {
        return TableInfo.data[TableInfo.row.index].status
          ? TableInfo.data[TableInfo.row.index].description
          : "NA";
      },
    },

    {
      Header: "Approve",
      accessor: "approve",
    },
    {
      Header: "Decline",
      accessor: "decline",
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: (TableInfo: TableProps) => {
        return TableInfo.data[TableInfo.row.index].status
          ? TableInfo.data[TableInfo.row.index].status
          : "";
      },
    },
  ];
  return COLUMNS;
};
